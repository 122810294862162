import React from 'react'
import aboutimg1 from '../../images/about/maltaimg2.jfif'
import './css/projectsBanner.css'

const ProjectsBanner = () => {
  return (
        <div className="projectimg_Total">    
            <div className='projectimgMain'>
                <img src={aboutimg1}  alt='' className='projectimage' />
            </div>
            <div className='projectHeading'>
                <h2>How do we do this?</h2>
               {/* make your career with us */}
                <p style={{paddingTop:'17px'}}> Our services are being anchored by a team of professionals who have 20 years of comprehensive experience.</p>
                
            </div>
        </div>
  )
}

export default ProjectsBanner