import React, { Fragment } from 'react';
import '../about/css/content.css';
import aboutimg7_low from '../../images/about/aboutimg7_low.png';
import aboutimg8 from '../../images/about/aboutimgcircle8.png';
import aboutimg9 from '../../images/about/aboutimg9.jpg'

const Content = () => {
    return (
       
           <Fragment>
                <div className="Total_Content">
                    <div className='Left_Content' data-aos="zoom-in" data-aos-duration="2500">
                        <img src={aboutimg7_low} alt='' className='leftContentImage1' />
                        <img src={aboutimg9} alt="" className='leftContentImage2'  />
                    </div>
                    <div className='Right_Content' data-aos="zoom-in" data-aos-duration="2500">
                        <img src={aboutimg8} alt="" className='RightImage'/>
                        <div className='RightaboutContent'>
                            <h2>About Us</h2>
                            <p>
                            We are constantly on the lookout for excellence. Our clients and candidates are of utmost importance and meeting our customers’ requirements within the time frames is our primary focus. We understand that perspectives are diverse, and we solicit valuable feedbacks from our clients and our candidates. We are dedicated to harness talent and nurture skills. Match candidate skillset with client requirements. We constantly meet and exceed the expectations of our clients 
                            </p>
                        </div>
                    </div>
                </div>     
           </Fragment>
    )
}

export default Content;