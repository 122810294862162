import React from 'react';
import { FaFacebookSquare,  FaMobileAlt } from 'react-icons/fa';
import { HiMail } from 'react-icons/hi';
import './css/header.css';
const Header = () => {
    return (
        <header>
            <div>
                <div className='top_nav-details'>
                    <div><a href='tel:+356 21381005' ><FaMobileAlt /> +356 21381005</a></div>
                    <div><a href='mailto: info@quickjobsmalta.com' ><HiMail style={{ fontSize: '1.3rem' }} />  info@quickjobsmalta.com</a></div>
                </div>
                <div className='top_nav-socials'>
                    <div className='socialmedia_icons'>
                        <li><a href='https://www.facebook.com/quickjobsmalta'><span style={{fontSize:'20px',paddingLeft:'50px'}}><FaFacebookSquare /></span>  </a></li>
                        {/* <li><a href='https://www.youtube.com/'><FaInstagram /></a></li>
                        <li><a href='https://www.youtube.com/'><FaTwitter /></a></li>
                        <li><a href='https://www.youtube.com/'><FaInstagram /></a></li> */}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
