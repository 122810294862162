export const projectData = [
    {
        "id":1,
        "title":"project 1",
        "status":"Completed",
        "img":"../../images/banner/b5.jpg",
        "description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        "id":2,
        "title":"project 2",
        "status":"OnGoing",
        "img":"../images/banner/54.jpg",
        "description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        "id":3,
        "title":"project 3",
        "status":"UpComing",
        "img":"../images/banner/img9.jpg",
        "description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
        "id":4,
        "title":"project 4",
        "status":"UpComing",
        "img":"../images/banner/img3.jpg",
        "description":"Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    }
]

export const multipleImages =[
    {
        "img":"../images/service.jpg",
        "title":"harshitha"
    },
    {
        "img":"../images/lightbox-img1.png",
        "title":"harshitha"
    },
    {
        "img":"../images/service.jpg",
        "title":"harshitha"
    },
    {
        "img":"../images/lightbox-img3.png",
        "title":"harshitha"
    }
]