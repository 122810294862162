import React from 'react';
import { NavLink , withRouter} from 'react-router-dom';
import logo from '../../images/QJM.png';

import './css/menu.css'
import BurgerMenu from './BurgerMenu';

const isActive = (history, path) => {
    if (history.location.pathname === path) {
      return {   color:' var(--mainColor)',borderBottom: '2px solid var(--mainColor)' };
    } else {
      return { color:'var(--paraGraphs)' };
    }
  };

const Menu = ({history}) => {
    return (
        <nav>
            <div>
                <div>
                    <NavLink to='/'><img src={logo} alt='' style={{width:'95%',height:'75%',paddingTop:20}} /></NavLink>        
                </div>
                <ul className='top-nav'>
                    <li><NavLink style={isActive(history, '/')} to='/'>home</NavLink></li>
                    <li><NavLink style={isActive(history, '/about')} to='/about'>about</NavLink></li>
                    <li><NavLink style={isActive(history, '/gallery')} to='/gallery'>gallery</NavLink></li>
                    <li><NavLink style={isActive(history, '/projects')} to='/projects'>Services</NavLink></li>
                    <li><NavLink style={isActive(history, '/contact')} to='/contact'>contact</NavLink></li>
                </ul>
                
                <BurgerMenu />
            </div>
        </nav>
    )
}

export default withRouter(Menu);
