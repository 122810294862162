/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import LightBox from '../../lightbox/lightbox';
import './css/services.css';

const Services = () => {
    return (
            <>
                <div className='bgcolor'>
                    <div >
                        <div className='ripple-background'>
                            <div className='circle xxlarge shade1'></div>
                            <div className='circle xlarge shade2'></div>
                            <div className='circle large shade3'></div>
                            <div className='circle mediun shade4'></div>
                            <div className='circle small shade5'></div>
                        </div>
                    </div>
                    <div className="bg-gallery">
                        <div className='artGallery'>
                            <h1>WHY MALTA?</h1>
                            <h5>what to know before you visit?</h5>
                            <p>An employee in Malta doesn’t care about this, but he would be interested in climate, way of life, opportunities, etc.</p>
                            {/* <p>
                            One of the world's best climates, Malta offers excellent schooling, healthcare and an overall safe environment.
                            </p> */}
                        </div>
                    </div>
                </div>
            <div className="gallery-heading-image">
                <h1>images</h1>
            </div>
            <LightBox />
            {/* <div className="gallery-heading-video">
                <h1>videos</h1>
                <div className="gallery-video-main">
                    <div className="video-content-one">
                        <iframe width="100%" height="240px"
                            src="https://www.youtube.com/embed/dtqYYVZWw3s"
                            frameBorder="0" className="video-common"></iframe>
                    </div>
                    <div className="video-content-two">
                        <iframe width="100%" height="240px"
                            src="https://www.youtube.com/embed/QLcYekD3BFs"
                            frameBorder="0" className="video-common"></iframe>
                    </div>
                </div>
            </div> */}
            </>
         
    )
}

export default Services;
