import React, { useState } from 'react';
import Banner from './banner';
import ChooseUs from './choose';
import './css/home.css';
import { Button,IconButton, makeStyles } from '@material-ui/core';
import RecentWork from './recentWork';
// import Testimonials from './testimonials';
// import Township from './township';
import CloseIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import watsapp from '../../../components/images/watsapp.png'
import logo from '../../../components/images/ONLYLOGO1.png'



const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: '6px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    totalDiv: {
      position: 'fixed',
      right: "20px",
      bottom: '80px',
      zIndex: 999999,
      backgroundColor: "white",
      // height:"320px" ,
      transition: "all 0.3s ease",
      width: "300px",
      borderRadius: "10px",
      // border:"1px solid blue"   //watspp div
    },
    blueDiv: {
      backgroundColor: "var(--primaryColor)",
      height: "80px",
      width: "300px",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      display: "flex",
      flexDirection: "row",
      zIndex:999999
    },
    blueDivImg: {
      height: "80px",
      width: "100px",
      position: "relative",
      marginTop: 10,
      marginLeft: 10,
    },
    whitecircle: {
      backgroundColor: "white",
      borderRadius: "50%",
      height: "60px",
      width: "60px",
      display:"flex",
      // position: "relative",
      justifyContent:"center",
      alignItems:"center"
  
    },
    blueDivText: {
      alignItems: "start",
      justifyContent: "start",
      paddingTop: 10,
      // border:"1px solid red",
      width: "260px",
      paddingLeft: 2
    },
    blueDivp: {
      paddingTop: 5,
      margin: 0,
      color: "white",
      fontWeight: "bold",
      fontSize: "18"
    },
    blueDivSpan: {
      fontSize: 12,
      color: "white",
      padding: 0,
      margin: 0,
    },
    grievanceStyle: {
      position: 'fixed',
      left: -91,
      top: '60%',
      zIndex: 999,
      transform: 'rotate(270deg)'
    },
    feedBackStyles: {
      position: 'fixed',
      left: -38,
      bottom: '8%',
      zIndex: 999,
      transform: 'rotate(270deg)'
    },
    feedbackMain: {
      justifyContent: 'center',
      width: '80%',
      margin: '0 auto'
    },
    parentsMain: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0.5rem',
      margin: '0.3rem 0'
    },
    studentsMain: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0.5rem',
      margin: '0.3rem 0'
    },
    teachersMain: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0.5rem',
      margin: '0.3rem 0'
    },
    aluminiMain: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0.5rem',
      margin: '0.3rem 0'
    },
    employerMain: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0.5rem',
      margin: '0.3rem 0'
    },
    bosMain: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0.5rem',
      margin: '0.3rem 0'
    },
    msgInputMain: {
      textAlign: 'center',
      backgroundColor: '#c1c1c1',
      padding: '15px',
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
     
    },
    whatsappMain: {
      position: 'fixed',
      right: 20,
      bottom: 20,
      zIndex: 1002
    },
    supportmsgMain: {
      backgroundColor: '#e5dcd5',
      paddingTop: '20px',
      paddingBottom: '20px',
      display:"flex",
    },
    msgTriangle:{
        width: 0,
        height: 0,
        borderTop: "15px solid white",
        borderLeft:" 15px solid transparent",
        borderTopLeftRadius: "5px",
      
    
    },
  
  }));

const Home = () => {
    const classes = useStyles();
    const [watsappOpen, setWatsappOpen] = useState(false);
    const [message, setMessage] = useState('');
  
 
    return (
        <div>
            <Banner />
            <ChooseUs />
            {/* <Township /> */}
            <RecentWork />
            {/* <Testimonials /> */}

            <div className='gallery'>
                <div></div>
            </div>
            <div className={classes.whatsappMain}>
          <div>
          <div onClick={() => setWatsappOpen(!watsappOpen)} style={{ textTransform: "none", cursor: 'pointer' }}>
            <img src={watsapp}alt="watsapp" height="50px" width="50px" />
          </div>

          <div className={classes.totalDiv} style={{ display: watsappOpen ? "block" : "none" }}>
            <div className={classes.blueDiv} >
              <div className={classes.blueDivImg}>
                <div className={classes.whitecircle}>
                  <img src={logo} alt="logo" height="70%" width="70%"  />
                </div>
              </div>
              <div className={classes.blueDivText} >
                <p className={classes.blueDivp}>Quick Jobs Malta</p>
                <span className={classes.blueDivSpan}>Typically replies with in 6hours</span>
              </div>
              <div >
                <IconButton aria-label="close" style={{ color: "white", }} onClick={() => setWatsappOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.supportmsgMain}>
              <div className={classes.msgTriangle}></div>
              <div style={{backgroundColor:"white",  width:"fit-content", padding:"2px 10px", marginLeft:0,borderTopRightRadius: "5px",borderBottomRightRadius:"5px", borderBottomLeftRadius:"5px"}}>
                <div>
                  <span style={{ color: '#d0d0d0' }}>Support</span>
                  <p style={{ margin: '0', paddingTop: '20px', color: 'black' }}>Hi</p>
                  <p style={{ margin: '0', color: 'black' }}>How can i help you?</p>
                  <p style={{ float: 'right', color: '#d0d0d0' }}>18:58</p>
                </div>
              </div>
            </div>
            <div className={classes.msgInputMain}>
              <div component="form" className={classes.msgInput}>
                <InputBase
                style={{ backgroundColor:"white", borderRadius:"20px", padding:"2px 10px",width:"90%"}}
                  placeholder="Type a message.."
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <a href={`https://wa.me/35621381005?text=${message}`} target="_blank" rel="noreferrer" > <Button variant="contained" style={{ borderRadius: '50px', textTransform: 'capitalize', backgroundColor: 'var(--primaryColor)', color: 'white', width: '80%', marginTop: '10px' }}>Start Chat</Button></a>
            </div>
          </div>
        </div>
      </div>

        </div>
    )
}

export default Home;
