import React, { Fragment ,useState,useEffect} from 'react';
import {projectData} from '../../data/projectData';
import {multipleImages} from '../../data/projectData';
import './css/singleProject.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Backimg from '../about/backimg';

const SingleProject = ({match}) => {

    const [project,setProject] = useState([]);
    const [ImageData] = useState(multipleImages);

    const value = match.params.projectId;

    useEffect(() => {
        init()
        // eslint-disable-next-line
      }, []);

      const init = () =>{
          filterData(value);
      }

      const filterData = (id) => {
        const newValue = projectData.filter(item => {
            return item.id === parseInt(id);
          });
        setProject(newValue);
        }

    return (
        <Fragment>
            <Backimg />

            {project.length && project.map((item,i)=>(
                <div key={i} className='singleProject'>
                    <div className='singleProject-img'>
                        <Carousel showArrows='true' autoPlay infiniteLoop>
                            {ImageData && ImageData.map((url,i)=><img src={url.img} alt='' key={i} style={{objectFit:'cover',height:'100%',width:'100%'}} />)}
                        </Carousel>
                    </div>
                    <div className='singleProject-content'>
                        <div>
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                            <p>{item.description}</p>
                            <p>{item.description}</p>
                            <p>{item.description}</p>
                        </div>
                    </div>
                </div>
            ))}

        </Fragment>
    )
}

export default SingleProject;
// <div className='contact-header'></div>
