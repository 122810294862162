import React, { Fragment } from 'react';
import Content from '../about/content';
import Director from '../about/director';
import Backimg from '../about/backimg';
// import Team from './ourteam';
import '../about/css/about.css';


const About = () => {
    return (
        <Fragment>
            <Backimg />
            <Content />
            <Director />
            {/* <Team /> */}
        </Fragment>
       
    )
}

export default About;
