import React from "react";
// import ReactDom from "react-dom";
import Gallery from './Gallery';

const LightBox = () => {
    return(
        <div style={{paddingBottom:'100px'}}>
            {/* <h1>hello react</h1>
            <p>we are learning react</p> */}
            <Gallery />
        </div>
    );
}

export default LightBox;