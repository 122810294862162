import React from 'react';
import {NavLink} from 'react-router-dom';
import {FaFacebookSquare, FaMobileAlt,FaMapMarkedAlt} from 'react-icons/fa';
import {HiMail} from 'react-icons/hi';
import bg from '../../images/MALTA.png';
import './css/footer.css';

const Footer = () => {
  return (
    <footer>
      <div className='footer-main'>
       
        {/* <div className="wave" id="wave1">
         
        </div>
        <div className="wave" id="wave2">
         
        </div>
        <div className="wave" id="wave3">
         
        </div>
        <div className="wave" id="wave4">
         
        </div> */}
         <div className='footer-inner'>
            <div className='div1'>
                <NavLink to='/'>
                  <div style={{width:"130px",height:"130px", borderRadius:"50%", backgroundColor:"white", alignItems:"center",textAlign:"center",padding:"20px 10px 10px 10px"}}>
                  <img  alt='Quick Jobs Malta'src={bg} width='60%' height='85%'  />   
                  </div>       
                </NavLink>
            </div>
            <div>
            <p style={{fontSize:20, fontWeight:'bold',color:"white"}}>Quick links</p>
              <ul>
                <li>
                <NavLink to='/'>Home</NavLink>
                </li>
                <li>
                <NavLink to='/gallery'>Gallery</NavLink>
                </li>
                <li>
                <NavLink to='/about'>About</NavLink>
                </li>
                <li>
                <NavLink to='/projects'>Projects</NavLink>
                </li>
                <li>
                <NavLink to='/contact'>Contact</NavLink>
                </li>              
              </ul>
            </div>
            <div>
            <p style={{fontSize:20, fontWeight:'bold',color:"white"}}>Contact Details</p>
            <ul>
                <li><a href='mailto:info@quickjobmalta.com'><HiMail/> info@quickjobmalta.com</a></li>
                <li><a href='tel:+356 21381005'><FaMobileAlt /> +356 21381005</a></li>
                <li><a href='https://goo.gl/maps/i7XZTvpvdizEre5r6' target="_blank" rel="noreferrer"><FaMapMarkedAlt /> 12A TRIQ GORG BORG OLIVIER, SAN GILJAN, STJ 1083. MALTA</a></li>
            </ul>
            </div>
            <div className='lastText'>
            <p >Quick Jobs Malta is a unique and flourishing consultancy agency in Malta. Quick Jobs Malta has gained a considerable network of contact. We specialize in providing the best of services.</p>
              <div className='socialmedia_icons'>
                  <li style={{color:'white'}}>Social link:  <a href='https://www.facebook.com/quickjobsmalta'><i><FaFacebookSquare /></i></a></li>
                  {/* <li><a href='https://www.youtube.com/'><FaInstagram /></a></li>
                  <li><a href='https://www.youtube.com/'><FaTwitter /></a></li>
                  <li><a href='https://www.youtube.com/'><FaInstagram /></a></li> */}
              </div>
            </div>
        </div>
        <div className='copyRights'>
              <p>Copyright © 2023 QuickJobsMalta. All Rights Reserved.</p>
              <p>Design & Developed by <b style={{color:"#fff"}}>EverestThinkers</b></p>
        </div>
       
      </div>
     
    </footer>
  );
};

export default Footer;
