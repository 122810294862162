import React, {useState, Fragment } from 'react';
import emailjs from 'emailjs-com';
import {FaFacebookSquare, FaMobileAlt,FaMapMarkedAlt} from 'react-icons/fa';
import {HiMail} from 'react-icons/hi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/contact.css';
const Contact = () => {
    // const [value, setValue] = React.useState('No');

    // const handleChangeRadio = (event) => {
    //   setValue(event.target.value);
    // };
  
    const [data ,setData] = useState({
        fname:'',
        phone:'',
        email:'',
        comments:''
    });

    const sendEmail = (e) => {
        e.preventDefault();
        
        console.log('__form submit',data);
        // console.log('radio', value)
        emailjs.sendForm('service_x49v51j', 'template_s7o285u', e.target, 'ZlujjQokwVzC0ezr7')
          .then((result) => {
              toast.success('We will contact you as soon as possible!');
              console.log(result.text);
          }, (error) => {
              toast.error(error.text)
              console.log(error.text);
          });
        e.target.reset();
        setData({
          fname: '',
          email: '',
          phone: '',
        //   company: '',
          comments: '',
        })
      }
    
    const handleChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

    return (
        <Fragment>
        <ToastContainer />
            <div className='contact-header'></div>

            <div className='contact-block'>
                <div className='contact-details'>
                    <div>
                        <h2 >let's meet</h2>
                        <div className='hidden-content'>
                            <div className='contact-info'>
                                <div><a href='tel:+356 21381005' ><FaMobileAlt /> +356 21381005</a></div>
                                <div><a href='mailto:info@quickjobmalta.com' ><HiMail /> info@quickjobmalta.com</a></div>
                                <div><a href='https://goo.gl/maps/i7XZTvpvdizEre5r6' target="_blank" rel="noreferrer" ><FaMapMarkedAlt /> 12A TRIQ GORG BORG OLIVIER, SAN GILJAN, STJ 1083. MALTA</a></div>
                            </div>
                            <div className='socialmedia' >
                                <li><a href='https://www.facebook.com/quickjobsmalta'><i><FaFacebookSquare /></i> facebook </a></li>
                                {/* <li><a href='https://www.youtube.com/'><FaInstagram /></a></li>
                                <li><a href='https://www.youtube.com/'><FaTwitter /></a></li>
                                <li><a href='https://www.youtube.com/'><FaInstagram /></a></li> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contact-form' >
                    <div>
                        <h2>Fill Out The Form Below To <span style={{color:"#0b3a80"}}>Get In </span><span style={{color:"var(--mainColor)"}}>Touch</span> With Us</h2>
                        <p style={{textAlign:"center"}}>Feel Free To Contact Us Or Send A Message</p>
                        <form onSubmit={sendEmail}>
                            <div className='group'>
                                <input type='text'
                                 className='form-input' 
                                 placeholder='Enter your Name' 
                                 name='fname'
                                 value={data.fname} 
                                 onChange={handleChange}
                                 required
                                 />
                            </div>
                            <div className='group'>
                                <input 
                                 className='form-input'
                                  placeholder='Enter your Phone Number'
                                  name='phone'
                                  value={data.phone} 
                                  onChange={handleChange}
                                  required 
                                  />                        
                            </div>
                            <div className='group'>
                                <input type='email' 
                                className='form-input'
                                name='email'
                                 placeholder='Enter your Email'
                                 value={data.email} 
                                 onChange={handleChange}
                                 required 
                                  />                            
                            </div>
                            {/* <div>
                                <FormControl component="fieldset">
                                <FormLabel component="legend" style={{color:"blue"}}>Accomodation</FormLabel>
                                    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChangeRadio} style={{display:"flex",flexDirection:"row"}}>
                                        <FormControlLabel value="No" control={<Radio size='small'/>} label="No" />
                                        <FormControlLabel value="Yes" control={<Radio size='small' />} label="Yes" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            */}
                            <div className='group'>
                                <textarea 
                                 className='form-input'
                                  placeholder='Comments'
                                  name='comments'
                                  value={data.comments} 
                                  onChange={handleChange}
                                  required
                                   rows="6"
                                    cols="50" />                        
                            </div>

                            <input type='submit' value='Submit' className='btns' />
                        </form>
                    </div>
                </div>
            </div>
            <div style={{ margin: '0rem 0rem' ,display:"flex"}}>
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3231.2494717435875!2d14.491711214536306!3d35.91639322464785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x130e45405e961383%3A0x71e0e0970b33a6b5!2s54%20George%20Borg%20Olivier%20St%2C%20St%20Julian&#39;s%2C%20Malta!5e0!3m2!1sen!2sin!4v1677059608417!5m2!1sen!2sin" 
                     width="100%" height="450" 
                     style={{border:0}} allowfullscreen="" loading="lazy" title="OLIVIER"
                     referrerpolicy="no-referrer-when-downgrade">
                     </iframe>
            </div>
            <div style={{alignItems:"center",textAlign:"center",color:"#0b3a80", margin:"3rem auto",}}>   
                <p  style={{fontSize:20, margin:"20px auto", width:"55%"}}>Outdoor Platform is an excellent Out sourcing company and here at Quick Jobs Malta we offer you Quick Jobs. Outdoor Platform is an excellent Out sourcing company and here at Quick Jobs Malta we offer you Quick Jobs.</p>
                <p style={{fontSize:18, margin:"10px auto", width:"56%"}}>
                12A TRIQ GORG BORG OLIVIER, SAN GILJAN, STJ 1083. MALTA
                </p>
                <p style={{fontSize:18, margin:"10px auto"}}><span style={{fontWeight:"bolder", color:"var(--mainColor)"}}>Email: </span>info@quickjobmalta.com</p>
                <p style={{fontSize:18, margin:"10px auto"}}><span style={{fontWeight:"bolder",color:"var(--primaryColor)"}}>Contact Us On: </span>+356 21381005</p>
                <p style={{fontSize:18, margin:"10px auto"}}><span style={{fontWeight:"bolder",color:"var(--mainColor)"}}>Whatsapp No: </span><a href={`https://wa.me/393925532464?text`} target="_blank" rel="noreferrer">+356 21381005</a></p>
                     
            
            </div>
           
        </Fragment>
    )
}

export default Contact;
