import React from 'react';
import {Link} from 'react-router-dom';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import image1 from '../../images/about/img7.jpg';
import image2 from '../../images/about/img8.jpg';
import image3 from '../../images/about/img10.jpg';

import './css/Banner.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Banner = () => {
  return (
    <section>
      <AutoplaySlider
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={3000}
        bullets={false}
        className='aws-btn'
      >
        <div data-src={image1} className='bannerImg' alt='work'>
            <div >
                <div style={{margin:"auto 0px" ,justifyContent:"space-around", height:"40%",paddingTop:"10px"}}>
                    <h2 >
                      Welcome To Quick Jobs {' '}
                      <span style={{ color: 'var(--mainColor)' }}>Malta</span>{' '}
                    </h2>
                    <p>Quickest way to jobs in Malta</p>
                    <Link to='/work' className='button'>Search Jobs</Link>
                    </div>
            </div>

        </div>

        <div data-src={image2} className='bannerImg' alt='Services'>
        <div >
                <div style={{margin:"auto 0px" ,justifyContent:"space-around", height:"40%",paddingTop:"10px"}}>
                    <h2 >
                      We Provide High Quality {' '}
                      <span style={{ color: 'var(--mainColor)' }}>Services</span>{' '}
                    </h2>
                    <p style={{padding:'0 45px',paddingBottom:'20px'}}>We are a team of passionate people that provide quality services through recruitment assistance, temping agency and work permit applications.</p>
                    <Link to='/work' className='button'>Find More Jobs</Link>
                    </div>
            </div>

        </div>
        
        <div data-src={image3} className='bannerImg' alt='Projects'>
        <div >
                <div style={{margin:"auto 0px" ,justifyContent:"space-around", height:"40%",padding:"10px 10px 0px 10px",}}>
                    <h2 >
                    A platform to find your job {' '}
                      <span style={{ color: 'var(--mainColor)' }}>in Malta</span>{' '}
                    </h2>
                    <p style={{paddingLeft:'18px'}}>We’ll help you to find the job you’re looking for</p>
                    <Link to='/work' className='button'>Find More Jobs</Link>
                    </div>
            </div>

        </div>
        {/* <div className='ripple-background bgcolor'>
            <div className='circle xxlarge shade1'></div>
            <div className='circle xlarge shade2'></div>
            <div className='circle large shade3'></div>
            <div className='circle mediun shade4'></div>
            <div className='circle small shade5'></div>
        </div> */}
      </AutoplaySlider>
    </section>
  );
};

export default Banner;

// <div>
// <h2 data-aos="fade-up" data-aos-duration="1000">
//   we provide high quality{' '}
//   <span style={{ color: '#3498db' }}>team</span>{' '}
// </h2>

// <p data-aos="fade-up" data-aos-duration="1200">We're Not Just Advertisers. We're Creators.</p>
// <Link to='/about' className='btn' data-aos="fade-up" data-aos-duration="1400">view team</Link>
// </div>