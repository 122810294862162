import React, { Fragment } from 'react';
import '../about/css/director.css';
import aboutimg6 from '../../images/about/aboutimg6.webp';
import officegirl8_low from '../../images/about/officegirl8_low.png';
import { FaPhoneAlt } from "react-icons/fa";

const Director = () => {
    return (
        <Fragment>
            <div className="About_Director">
                <div className='AboutleftContent'>
					<img src={aboutimg6} alt="" className='AbtLeftImg1' data-aos="fade-right" data-aos-duration="2500"/>
                    <img src={officegirl8_low} alt="" className='AbtLeftImg2' data-aos="fade-right" data-aos-duration="2500"/>
                </div>
                <div className='AboutRightContent'>
                    <h2>We’d Love To Help You</h2>
                    <p>We recognize the importance of getting to know the candidate and their career preference. We understand the goals and values and personality of the candidate. As an ideal career move, the first step of your career will be with Quick jobs Malta. We are focused on helping people develop their careers. With every candidate we are ambitious for their success and are passionate about offering a great customer experience. Please feel free to get in touch using the below contact number. We'd love to hear your thoughts & answer any questions you may have!
                    </p>
                    <div style={{display:'flex'}}>
                        <FaPhoneAlt  className='phnIcon' style={{marginTop:'25px'}}/>
                        <a href='#1234567890' className='dialNumber'>+356 21381005</a>    
                    </div>
                </div>
            </div>
        </Fragment>
        
    )
}

export default Director;