import React from 'react';
import chooseusimg2 from '../../images/banner/chooseusimg2.png';
// import {AiOutlineReload, AiOutlineFileSearch} from 'react-icons/ai';
// import {CgDesignmodo} from 'react-icons/cg';
import {BsPersonCheck} from 'react-icons/bs';
import {FaRegClock} from 'react-icons/fa';
import {ImPriceTags} from 'react-icons/im';
import {FaPeopleCarry} from 'react-icons/fa'

import './css/choose.css'

const ChooseUS = () => {

    return (
        <div className='choose-us'>
            <div className='choose-us_info'>
                <h2 className='chooseHeading'><span style={{color:"var(--mainColor)"}}>W</span>hy <span style={{color:"var(--mainColor)"}}>C</span>hoose <span style={{color:"var(--mainColor)"}}>U</span>s?</h2>
                {/* <p >hello world hello world...</p> */}
                <div className='home-services' data-aos="zoom-in-up" data-aos-duration="500">
                    <div className='home-services-items' >
                        <div className='home-services-logo'><a href='/' ><BsPersonCheck /> </a> </div>
                        <div>
                            <div>
                                <h3>We Value Our Clients</h3>
                            </div>
                            <div className='homeContent'>
                                <p>We only provide you with recommendations on services that can help improve your career.  </p>
                            </div>
                        </div>
                    </div>

                    <div className='home-services-items' >
                        <div className='home-services-logo'><a href='/' ><FaPeopleCarry/></a></div>
                        <div>
                            <div>
                                <h3>Long-Term Association</h3>
                            </div>
                            <div>
                                <p>Our firm is dedicated to long term partnerships with our clients.</p>
                            </div>
                        </div>
                    </div>

                    <div className='home-services-items' >
                        <div className='home-services-logo'><a href='/' ><ImPriceTags /></a></div>
                        <div>
                            <div>
                                <h3>Pricing</h3>
                            </div>
                            <div>
                                <p>Our prices are competitive and fair. There are no surprise bills. That’s how we would like to be treated, and that is how our clients are treated. </p>
                            </div>
                        </div>
                    </div>
                    
                    <div className='home-services-items' >
                        <div className='home-services-logo'><a href='/' ><FaRegClock /></a></div>
                        <div>
                            <div>
                                <h3>We Provide Full Consultations</h3>
                            </div>
                            <div>
                                <p>We’re great listeners and we’ll help you determine your career’s needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='choose-us_img'> <img src={chooseusimg2} alt='Quick jobs malta'  height='100%' width='100%'  /></div>
        </div>
    )
}

export default ChooseUS;
