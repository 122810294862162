import React from 'react';
import { NavLink } from 'react-router-dom';
// import bg from '../../images/service.jpg';
// import {projectData} from '../../data/projectData';
import './css/recentWork.css';
import AbstractImg from '../../images/banner/homeimg5.png'
import workimg1 from '../../images/banner/cab.jpg';
import workimg2 from '../../images/banner/chefs.jpg';
import workimg3 from '../../images/banner/fooddelievery1.jpg';
import workimg4 from '../../images/banner/cleaningservice.jpg';


const RecentWork = () => {
    // const [data] = useState(projectData);

    // console.log(data);

    return (
        <div className='recent-work'>
            <h2><span style={{color:"var(--mainColor)"}}>O</span>ur <span style={{color:"var(--mainColor)"}}>S</span>ervices</h2>
            {/* <p style={{textAlign:'left',padding:'0'}}>hello world hello world</p> */}
            <div className='recent-work_items'>       
                <div className='recent-work_singleitem' data-aos="zoom-in-up" data-aos-duration="500">
                    <div className='workContent'>
                        <img src={AbstractImg} alt="" />
                        <h2>1</h2>
                    </div>    
                    <div>
                        <img src={workimg1} alt='Quick Jobs projects' height='150px' width='100%' />
                    </div>
                    <div className='recent-work-heading'>
                        <h3>Cab Drivers</h3>
                        {/* <NavLink to={`/projects`} className='workbtn'>MORE</NavLink> */}
                    </div>
                    <p>Taxi drivers are usually paid according to the distance and time taken to complete each trip.</p>
                </div>
                <div  className='recent-work_singleitem' data-aos="zoom-in-up" data-aos-duration="600">
                    <div className='workContent'>
                        <img src={AbstractImg} alt=""  />
                        <h2>2</h2>
                    </div>    
                    <div>
                        <img src={workimg2} alt='Qiuck Jobs recent projects' height='150px' width='100%' />
                    </div>
                    <div className='recent-work-heading'>
                        <h3>Chefs and Waiters</h3>
                        {/* <NavLink to={`/projects`} className='workbtn'>MORE</NavLink> */}
                    </div>
                    <p>Several vacancies available in the cuisine industry. Start-up careers as waiters all the way up to qualified and experiences chefs.</p>
                </div>
                <div  className='recent-work_singleitem' data-aos="zoom-in-up" data-aos-duration="700">
                    <div className='workContent'>
                        <img src={AbstractImg} alt=""  />
                        <h2>3</h2>
                    </div>    
                    <div>
                        <img src={workimg3} alt='Quick Jobs recent projects' height='150px' width='100%' />
                    </div>
                    <div className='recent-work-heading'>
                        <h3>Food Delievery</h3>
                        {/* <NavLink to={`/projects`} className='workbtn'>MORE</NavLink> */}
                    </div>
                    <p>Food delivery is a courier service in which stores, restaurants, or third-party applications delivers food to consumers on demand.</p>
                </div>
                <div  className='recent-work_singleitem' data-aos="zoom-in-up" data-aos-duration="800">
                    <div className='workContent'>
                        <img src={AbstractImg} alt=""  />
                        <h2>4</h2>
                    </div>    
                    <div>
                        <img src={workimg4} alt='Quick jobs recent projects' height='150px' width='100%' />
                    </div>
                    <div className='recent-work-heading'>
                        <h3>Cleaning Service</h3>
                        {/* <NavLink to={`/projects`} className='workbtn'>MORE</NavLink> */}
                    </div>
                    <p>Affordable, Safe and Hygienic Cleaning from Experts, get full urban cleaning services. Professional Cleaning Services at your doorstep.</p>
                </div>
            </div>
            <div style={{display:"block",alignItems:"end",textAlign:"center"}}>
                 <NavLink to={`/projects`} className='workbtn'> SEE MORE</NavLink>

            </div>
        </div>
    )
}

export default RecentWork;
