import React,{Fragment} from 'react';
// import Backimg from '../about/backimg';
import AllServices from './allservices';
import ServicesContent from './servicesContent';
// import ClientReview from './clientReview';
import ProjectsBanner from './projectsBanner';


const Projects = () => {
    return (
        <Fragment>
                {/* <Backimg /> */}
                <ProjectsBanner />
                <AllServices />
                <ServicesContent />
                {/* <ClientReview /> */}
        </Fragment>
    )
}

export default Projects;
