import React, { Fragment } from 'react';
// import bg1 from '../../images/banner/b3.jpg';
import '../about/css/backimg.css';
// import {Link} from 'react-router-dom';
import aboutimg3 from '../../images/about/aboutimg3.jpg'

const Backimg = () => {
    return (
        <Fragment>
           
            <div className="Backimg_Total">
                <div className='aboutHeading'>
                        <h2>We Help People Find Great Jobs.</h2>
                        
                        <p style={{paddingTop:'20px'}}>We provide full process human resources solutions and services to your needs. </p>
                        
                </div>
                <div className='aboutimgMain'>
                        <img src={aboutimg3}  alt='' className='aboutimage' />
                </div>
              </div>
          
            
        </Fragment>
       
    )
}

export default Backimg;