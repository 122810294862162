import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import './css/mobilemenu.css';

const StyledBurger = styled.div`
  width: 2rem;
  height: 1.3rem;
  position: relative;
  top: 1.7rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  z-index: 99999;

  div {
    width: 1.8rem;
    height: 0.15rem;
    background-color: ${({ open }) => (open ? 'var(--primaryColor)' : 'var(--primaryColor)')};
    border-radius: 3px;
    transform-origin: 1px;
    transition: all 0.5s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(39deg)' : 'rotate(0)')};
    }

   :nth-child(2) {
     opacity: ${({ open }) => (open ? '0' : '1')};
     transform: ${({ open }) => (open ? 'translateX(-20px)' : 'translateX(0)')};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-39deg)' : 'rotate(0)')};
    }
  }
`;

const MenuNav = styled.div`
  position: fixed;
  top: 125px;
  right: 0;
  width: 100%;
  height: fit-content;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  background-color: var(--primaryColor);
  transition: transform 0.8s ease-in-out;
  z-index: 9999;
  padding-bottom:20px
`;


const BurgerMenu = () => {
  const [open, setOpen] = useState(false);
  // const [offsetX,setOffsetX] = useState(0);
  // const [offsetY,setOffsetY] = useState(0);

  // const cursor = document.querySelector('.cursor');

  // const handleMosueEffect = ()=> {
  //   cursor.style.left = offsetX+'px';
  //   cursor.style.top = offsetY+'px';
  // }


  // useEffect(()=>{
  //   const updateMouseEvent = (e) =>{
  //     setOffsetX(e.clientX);
  //     setOffsetY(e.clientY);
  //   }

  //   window.addEventListener("mousemove",updateMouseEvent);

  //   return ()=> window.removeEventListener("mousemove",updateMouseEvent);
  // })

  // console.log(open);
  return (
    <div className='mobile-menu-screen' >
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>

      <MenuNav open={open}>
        <div className='top-nav-mobile'>
            <ul>
              <li onClick={() => setOpen(!open)} ><NavLink to='/'>Home</NavLink></li>
              <li onClick={() => setOpen(!open)}><NavLink to='/about'>About</NavLink></li>
              <li onClick={() => setOpen(!open)}><NavLink to='/gallery'>Gallery</NavLink></li>
              <li onClick={() => setOpen(!open)}><NavLink to='/projects'>Services</NavLink></li>
              <li onClick={() => setOpen(!open)}><NavLink to='/contact'>Contact</NavLink></li>
              {/* <div className='cursor'></div> */}
            </ul>
        </div>
      </MenuNav>
      
    </div>
  );
};

export default BurgerMenu;