import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './components/layouts/home/home';
import About from './components/layouts/about/about';
import Gallery from './components/layouts/services/services';
import Projects from './components/layouts/projects/projects';
import Contact from './components/layouts/contact/contact';
import Menu from './components/layouts/navigation/menu';
import Header from './components/layouts/navigation/header';
import SingleProject from './components/layouts/projects/singleproject';
import Footer from './components/layouts/navigation/footer';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import './App.css';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import ScrollToTop from './components/layouts/navigation/ScrollTop';
// import ContactForm from './components/layouts/navigation/contactForm';

const ErrorPage =() =>(
  <div className='page_Error'>
    <h2>error page</h2>
  </div>
)

function App() {
  AOS.init();
  
  return (
    <Fragment>
    <div className='loader_bg'>
      <div className='loader'></div>
    </div>
      <ScrollToTop />
        <Header />
        <Menu />
        <Route render={({location}) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={450}
              classNames="fade"
            >
              <Switch location={location}>
                <Route exact path='/' component={Home} />
                <Route exact path='/about' component={About} />
                <Route exact path='/gallery' component={Gallery} />
                <Route exact path='/projects' component={Projects} />
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/work' component={Projects} />
                <Route exact path='/projects/:projectId' component={SingleProject} />
                <Route component={ErrorPage} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      )} />
      {/* <ContactForm/> */}
        <Footer />
    </Fragment>
  );
}

export default App;
