import React from 'react'
import AboutImg from '../../images/about/img3.jpg'
import './css/servicesContent.css'

const ServicesContent = () => {
  return (
    <div className='servicesContentMain'>
       <div className='servicesContentLeft'>
            <img src={AboutImg} alt='' />
       </div>
       <div className='servicesContentRight'>
            <h2>100% Satisfaction Guaranted</h2>
            <p>We take great pride in providing a more personal style of staffing. We truly listen to our clients so that we can best meet their needs. We thereby conclude the better solution is to opt for a personal and targeted approach.</p>
       </div>
    </div>
  )
}

export default ServicesContent