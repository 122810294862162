import React from 'react';
import './css/allservices.css';
import { NavLink } from 'react-router-dom';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
// import WcIcon from '@material-ui/icons/Wc';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import { GiBroom } from "react-icons/gi";
import ApartmentIcon from '@material-ui/icons/Apartment';
import {FaToolbox} from 'react-icons/fa';
import {MdLocalPharmacy} from 'react-icons/md';
import {FaHeartbeat} from 'react-icons/fa';


const servicesData=[
    {
        "image":<AirportShuttleIcon style={{width:"50px", height:"50px"}}/>,
        "title":"Cab drivers",
        "content":"5 Positions Left",
        "index":"0"
    },
    {
        "image":<RestaurantIcon style={{width:"50px", height:"50px"}}/>,
        "title":"Waiters",
        "content":"5 Positions Left",
        "index":"1"
    },
    {
        "image":<FastfoodIcon style={{width:"50px", height:"50px"}}/>,
        "title":"Food Delievery",
        "content":"5 Positions Left",
        "index":"2"
    },
    {
        "image":<RestaurantMenuIcon style={{width:"50px", height:"50px"}}/>,
        "title":"Chefs",
        "content":"5 Positions Left",
        "index":"3"
    },
  
    {
        "image":<StoreMallDirectoryIcon style={{width:"50px", height:"50px"}}/>,
        "title":"Supermarkets",
        "content":"5 Positions Left",
        "index":"4"
    },
    {
        "image":<GiBroom style={{width:"50px", height:"50px"}}/>,
        "title":"Cleaning",
        "content":"5 Positions Left",
        "index":"6"
    },
    {
        "image":<ApartmentIcon style={{width:"50px", height:"50px"}}/>,
        "title":"Hospitality",
        "content":"5 Positions Left",
        "index":"7"
    },
    {
        "image":<FaToolbox style={{width:"50px", height:"50px"}}/>,
        "title":"Maintenance",
        "content":"5 Positions Left",
        "index":"8"
    },
    {
        "image":<MdLocalPharmacy style={{width:"50px", height:"50px"}}/>,
        "title":"Pharmaceutical",
        "content":"5 Positions Left",
        "index":"9"
    },
    {
        "image":<FaHeartbeat style={{width:"50px", height:"50px"}}/>,
        "title":"Health",
        "content":"5 Positions Left",
        "index":"10"
    },
    {
        "image":<FaHeartbeat style={{width:"50px", height:"50px"}}/>,
        "title":"Accomodation",
        "content":"Looking For Apartments or houses?",
        "index":"11"
    },
    {
        "image":<FaHeartbeat style={{width:"50px", height:"50px"}}/>,
        "title":"English Tution",
        "content":"The Faster Way to learn English.",
        "index":"12"
    },
    
];



const AllProjects = () => {
    // const [data] = useState(projectData);

    // const[dispImg,setDisp]=useState('');
    // const[cardIndex,setIndex]=useState(0);
    // const[dispImgStyle,setStyle]=useState({display:'none'});


    // const showImage=(event)=>{
    //     setDisp(servicesData[Number(event.target.id)].image);
    //     setIndex(Number(event.target.id));
    //     setStyle({display:'flex'});
    // }

   
    const card=servicesData.map(function(obj,ind){
        // console.log(obj.image);
        return(
        <div className='servicescard' key={ind}>
            <div className='cardMain'>
                {obj.image}
                <h1 className="card--title">{obj.title}</h1>
                {/* <p className='cardInfo'>{obj.content}</p> */}
                 <div style={{display:"block",alignItems:"end",textAlign:"center", marginTop:"10px"}}>
                 <NavLink to={`/contact`} className='workbtn'> REACH NOW</NavLink>

            </div>
            </div>    
            
        </div>)
    });
   

    return(
        <div className="gallery-lightbox">
            <div className="Total_Project">
                <h2><span style={{color:"var(--mainColor)"}}>O</span>UR <span style={{color:"var(--mainColor)"}}>S</span>ERVICES</h2>     
                <div className="Project_container">

                </div>
            </div>
            <section className="containers">
                {card}
              
            </section>
       </div>
    );
}

export default AllProjects;
