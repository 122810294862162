import React, {useState} from 'react';
import './Gallery.css';
import galleryimg from '../images/gallery/gallery-img.jpg';
import galleryimg1 from '../images/gallery/gallery-img1.jpg';
import galleryimg2 from '../images/gallery/gallery-img6.jpg';
import galleryimg3 from '../images/gallery/gallery-img3.jpg';
import galleryimg4 from '../images/gallery/gallery-img4.jpg';
import galleryimg5 from '../images/gallery/gallery-img5.jpg'

const imagesData=[
    {
        "image":galleryimg,
        "title":"QUICK JOBS MALTA",
        // "uploadDate":"nov 23 2020",
        "index":"0"
    },
    {
        "image":galleryimg1,
        "title":"QUICK JOBS MALTA",
        // "uploadDate":"nov 23 2020",
        "index":"1"
    },
    {
        "image":galleryimg2,
        "title":"QUICK JOBS MALTA",
        // "uploadDate":"nov 23 2020",
        "index":"2"
    },
    {
        "image":galleryimg3,
        "title":"QUICK JOBS MALTA",
        // "uploadDate":"nov 23 2020",
        "index":"3"
    },
    {
        "image":galleryimg4,
        "title":"QUICK JOBS MALTA",
        // "uploadDate":"nov 23 2020",
        "index":"4"
    },
    {
        "image":galleryimg5,
        "title":"QUICK JOBS MALTA",
        // "uploadDate":"nov 23 2020",
        "index":"5"
    },
];

const Gallery = () =>{

    const[dispImg,setDisp]=useState('');
    const[cardIndex,setIndex]=useState(0);
    const[dispImgStyle,setStyle]=useState({display:'none'});


    const showImage=(event)=>{
        setDisp(imagesData[Number(event.target.id)].image);
        setIndex(Number(event.target.id));
        setStyle({display:'flex'});
    }

    const closeDisp=()=>{
        setStyle({display:'none'});

    }

    const prevShow=()=>{
        if(cardIndex===0){
            setIndex(imagesData.length-1);
            setDisp(imagesData[imagesData.length-1].image);
        }
        else{
            let decrement=cardIndex-1;
            setDisp(imagesData[decrement].image);
            setIndex(decrement); 
        }
    }
    
    const nextShow=()=>{
        if(cardIndex===imagesData.length-1){
            setIndex(0);
            setDisp(imagesData[0].image);
        }
        else{
            let increment=cardIndex+1;
            setDisp(imagesData[increment].image);
            setIndex(increment);
        }
    }

    const card=imagesData.map(function(obj,ind){
        // console.log(obj.image);
        return(
        <div className='card' key={ind}>
           <img src={obj.image}alt={obj.title}/>
            <div className="card--info">
            <h1 className="card--title">{obj.title}</h1>
            {/* <label className="card--upload--date">{obj.uploadDate}</label> */}
            <button id={ind} onClick={showImage} >preview</button>
            </div>     
        </div>)
    });

    return(
        <div className="gallery-lightbox">
            <section className="container">
                {card}
            </section>
            <section className="lightbox" style={dispImgStyle}>
                <div className="close" onClick={closeDisp}>close</div>     
                <div className="carousel left" onClick={prevShow}>
                    <span></span><span></span>
                </div>  
                <div className="carousel right" onClick={nextShow}>
                    <span></span><span></span>
                </div>  
                <img src={dispImg} alt={dispImg} />        
            </section>
       </div>
    );
}

export default Gallery;